import "./updateProduct.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Paper, TextField, Button, Typography, Link, Select, InputLabel, MenuItem, FormControl } from '@material-ui/core'
import { DeleteOutline } from "@material-ui/icons";
import axios from 'axios';
import { useHistory } from "react-router-dom";

export default function UpdateProduct({ match }) {
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [isActive, setIsActive] = useState();
  const [img_url, setImg_url] = useState("");
  const [img_address, setImg_address] = useState("");
  const [filename, setFilename] = useState("Choose Image");
  const [addons, setAddons] = useState([]);
  const [addonsPrice, setAddonsPrice] = useState();
  const [categories, setCategories] = useState([]);
  const [category, setSelectedCategory] = useState(null);

  const [productType, setProductType] = useState("single");
  const [productUnits, setProductUnits] = useState(2);
  const [maxAddons, setMaxAddons] = useState(1);
  const [isAddonsMandatory, setIsAddonsMandatory] = useState(false);

  const history = useHistory();

  const token = localStorage.getItem("token");
  const { productId } = match.params;

  useEffect(async () => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
      // get user to verify
      await axios.get(`${process.env.React_App_baseURL}/user/account`)
        .then(({ data }) => {
          if (data.session.user_id.role === "merchant") return;
        }).then(async () => {
          // get product details
          await axios.get(`${process.env.React_App_baseURL}/product/${productId}`)
            .then(({ data }) => {
              console.log(data.product);
              setProductName(data.product.product_name);
              setDescription(data.product.description);
              setPrice(data.product.price);
              setIsActive(data.product.is_active);
              setSelectedCategory(data.product.category);
              setImg_url(data.product.img_url);
              setAddons(data.product.addons);
              setProductType(data.product.product_type);
              setProductUnits(data.product.product_units);
              setMaxAddons(data.product.max_addons);
              setIsAddonsMandatory(data.product.is_addons_mandatory);
            });

            getAllCategories();
        })
    } else history.push("/login");
  }, [productId]);

  const getAllCategories = async () => {
    await axios.get(`${process.env.React_App_baseURL}/categories`)
            .then(({ data }) => {
              console.log(data.categories);
              setCategories(data.categories);
            });
  }

  const fileSelectedHandler = async (e) => {
    if (e.target.value) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImg_address(reader.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      setFilename(e.target.files[0].name);

      const fd = new FormData();
      fd.append('img_url', e.target.files[0]);

      axios.defaults.headers.common['Authorization'] = token;
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }

      await axios
        .put(`${process.env.React_App_baseURL}/product/uploadimage/${productId}`, fd, config)
        .then(({ data }) => toast.success(data.message))
        .catch((err) => {
          let message = err.response ? err.response.data.message : "Only image files are allowed!";
          toast.error(message)
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!productName || !price) return toast.error("Blank field is not allowed!");

    axios.defaults.headers.common['Authorization'] = token;

    const product = {
      product_name: productName,
      description: description,
      category: category,
      price: price,
      is_active: isActive,
      addons: addons || [],
      product_type: productType,
      product_units: productUnits,
      max_addons: maxAddons,
      is_addons_mandatory: true,//isAddonsMandatory
    }

    try {
      await axios
        .put(`${process.env.React_App_baseURL}/product/update/${productId}`, product)
        .then(({ data }) => {
          console.log(data);
          if (data.status === 200) {
            return toast.success(data.message);
          } else {
            toast.error("Product is not updated!, please try again");
          }
        });

    } catch (error) {
      toast.error("Product is not updated, please try again", error);
    }
  };

  const deleteAddon = (index) => {
    addons.splice(index, 1);
    setAddons([...addons]);
  }

  let start = 0;
  const handlePrice = (e) => {
    start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    e.target.value = value;
    // setInput(value);
    if (val.length > 0) {
      e.target.value = Number(value).toFixed(2);
      e.target.setSelectionRange(start, start);
      setPrice(Number(value).toFixed(2));
    }
  }

  const addAddon = () => {
    addons.push({ addon_name: "Option Name", price: "00.00" });
    setAddons([...addons]);
  }

  const updateAddon = (key, index, value) => {
    if (addons[index][key] !== value) {
      addons[index][key] = value;
      setAddons([...addons])
    }
  }

  const hanldeProductUnits = (e) => {
    let result = e.target.value.replace(/\./g, "");
    setProductUnits(result);
  }

  const hanldeMaxAddons = (e) => {
    let result = e.target.value.replace(/\./g, "");
    setMaxAddons(result);
  }

  const paperStyle = { padding: 20, width: 400, margin: "" }
  const btnstyle = { margin: '8px 0' }

  return (
    <div>
      <Topbar />
      <div className="container">
        <Sidebar />
        <div className="newProduct" style={{ display: 'flex' }}>
          <div >
            <Grid>
              <Paper elevation={0} style={paperStyle}>
                <Grid align='left'>
                  <h2>Update Product</h2>
                </Grid>
                <br />
                <TextField className="addProductItem" label='Product Name' placeholder='Enter Product Name' fullWidth name="product_name" value={productName} onChange={(e) => setProductName(e.target.value)} />
                <br />
                <TextField className="addProductItem" label='Description' placeholder="Description" fullWidth multiline rows={2} rowsMax={5} name="product_name" value={description} onChange={(e) => setDescription(e.target.value)} />
                <br /><br />

                  <InputLabel id="demo-simple-select-helper-label1">Category</InputLabel>
                  <Select labelId="demo-simple-select-helper-label1" id="demo-simple-select-helper1"
                    value={category} label="Category" onChange={(e) => setSelectedCategory(e.target.value)}
                    fullWidth defaultValue={null}>
                    {categories.length > 0 && categories.map(x => (
                      <MenuItem value={x._id}>{x.category_name}</MenuItem>
                    ))}
                  </Select>
                  <br /><br />
                <TextField className="addProductItem" type="tel" label='Price' placeholder='Enter Price' fullWidth name="price" value={price} onChange={handlePrice} />
                <br /><br />

                <InputLabel id="demo-simple-select-helper-label">Product Type</InputLabel>
                <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                  value={productType} label="Product Type" onChange={(e) => setProductType(e.target.value)}
                  fullWidth defaultValue="single">
                  <MenuItem value="single">Single</MenuItem>
                  <MenuItem value="bundle">Bundle</MenuItem>
                </Select>
                <br /><br />
                {productType === "bundle" && (
                  <div>
                    <TextField type="number" label='Product Units'
                      value={productUnits} onChange={hanldeProductUnits}
                      InputProps={{ inputProps: { min: 2 } }} />
                    <TextField type="number" label='Max Addons'
                      value={maxAddons} onChange={hanldeMaxAddons}
                      InputProps={{ inputProps: { min: 2 } }} />
                  </div>
                )}

                <br />
                {/* <div className="addProductItem">
                  <label style={{ fontWeight: '500' }}>Is Addons Mandatory ?</label>
                  <label className="switch">
                    <input type="checkbox" checked={isAddonsMandatory} value={isAddonsMandatory} onChange={() => setIsAddonsMandatory(!isAddonsMandatory)} />
                    <span className="slider round"></span>
                  </label>
                </div> */}

                <div className="addProductItem">
                  <label style={{ fontWeight: '500' }}>Active</label>
                  <label className="switch">
                    <input type="checkbox" checked={(isActive === true) ? true : false} name="is_active"
                      value={isActive} onChange={(e) => setIsActive(!isActive)} />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="addProductItem">
                  <Button variant="contained" component="label" >Choose Image
                    <input type="file" name="img_url" hidden onChange={fileSelectedHandler} accept="image/*" />
                  </Button>
                  <div><small>Only jpg, png, gif, svg images are allowed with max size of 5 MB</small></div>
                </div>
                <Button onClick={handleSubmit} type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Update Product</Button>
                <br /><br />
                <Typography >
                  <Link href="/products" >
                    Back to Product List
                  </Link>
                </Typography>
              </Paper>
            </Grid>
            <ToastContainer position="top-right" />
          </div>
          <div className="ProductImage" style={{ width: '300px', margin: "50px 20px", alignContent: 'center', border: '3px dashed grey', display: 'flex', height: '300px', alignItems: 'center', flexDirection: 'column' }}>
            {(img_address) ? (<img className="img-object-fit" src={img_address} style={{ margin: "0 auto", borderRadius: '50px', height: '300px', width: '300px' }}></img>) :
              (<img className="img-object-fit" src={`${process.env.React_App_baseURL}/uploads/${img_url}`} style={{ margin: "0 auto", borderRadius: '50px', height: '300px', width: '290px' }}></img>)}
          </div >
          <div className="addon-wrapper">
            <Button variant="contained" component="label" onClick={addAddon} >Add Option</Button>

            <div className="addon-list">
              {addons.map((x, i) => (
                <div className="addon-item">
                  <input className="addon-name" type="text" key={`addonn_${i}`} value={addons[i].addon_name} onChange={(e) => updateAddon('addon_name', i, e.target.value)} />
                  {/* <input className="addon-price" type="number" key={`addonp_${i}`} value={addons[i].price} onChange={(e) => updateAddon('price', i, e.target.value)} /> */}
                  <div className="addon-actions">
                    <DeleteOutline onClick={() => deleteAddon(i)} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <br />
        </div >
      </div >
      <ToastContainer position="top-right" />
    </div >
  );

}

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Close from '@material-ui/icons/Close';
import { Button, Grid } from '@material-ui/core';
import moment from "moment";

export default function PackagingDetail(props) {
  const packageData = props.package.data ? props.package : {data: []};
  const [tableData, setTableData] = useState({customers: [], products: [], addons: [], categories: []});
  const [quantityBunch, setQuantityBunch] = useState({orders: [], products: [], addons: [], total: 0});
  useEffect(() => {
    formatData();
  }, [props.package.data])

  const history = useHistory();

  const formatData = () => {
    const customers = packageData.data;

    customers.forEach((order) => {
      const categoryList = [];
      order.products.forEach(product => {
        const productCategory = product.productId.category;
        const categoryFound = categoryList.find(x => x.category._id == productCategory._id);
        if (categoryFound) {
          categoryFound['products'].push(product);
        } else {
          categoryList.push({category: productCategory, products: [product]})
        }
      });
      categoryList.sort((a,b) => {
        return (a.category.category_name < b.category.category_name) ? (a.category.category_name > b.category.category_name) ? 1 : -1 : 0;
      })
      order.categories = categoryList;
    })
    
    let productsList = packageData.data.map(x => x.products);
    productsList = productsList.flatMap(x => x);
    productsList = [
      ...new Map(productsList.map((item) => [item.productId['_id'], item])).values(),
    ];

    let addonsList = productsList.map(x => x.productId.addons);
    addonsList = addonsList.flatMap(x => x);
    addonsList = [
      ...new Map(addonsList.map((item) => [item["_id"], item])).values(),
    ];

    const categories = [];
    productsList.forEach(pd => {
      if (!pd.productId.category) pd.productId.category = {_id: "anonymous", category_name: "Anonymous"};
      const existedCategory = categories.find(ct => ct.category._id == pd.productId.category._id);
      if (existedCategory) { 
        existedCategory.products.push(pd); 
      } else {
        categories.push({
          category: pd.productId.category,
          products: [pd],
        })
      }
    })

    categories.sort((a,b) => {
      return (a.category.category_name < b.category.category_name) ? (a.category.category_name > b.category.category_name) ? 1 : -1 : 0;
    })

    // const categories = [];
    // customers.forEach((order) => {
    //   order.products.forEach(product => {
    //     const productCategory = product.productId.category;
    //     const categoryFound = categories.find(x => x.category._id == productCategory._id);
    //     if (categoryFound) {
    //       const productFound = categoryFound['products'].find(x => x.productId._id == product.productId._id);
    //       if (productFound) {
    //         productFound.quantity += product.quantity;
    //       } else {
    //         categoryFound['products'].push(product);
    //       }
    //     } else {
    //       categories.push({category: productCategory, products: [product]})
    //     }
    //   });
    // })

    console.log(addonsList, productsList, customers, categories)
    const value = {products: productsList, addons: addonsList, customers: customers, categories: categories};
    setTableData({...value});

    calculateQuantities();
  }

  const calculateQuantities = () => {
    const orders = packageData.data;
    const aggregatedQuantities = {
      products: {},
      addons: {},
      orders: {},
      total: 0
    }
    
    if(orders.length > 0) {
      
      orders.forEach(element => {
        const orderId = element._id;
  
        // Calculate Quantity by Customer
        let prodQuantities = element.products.map(x => x.quantity);
        prodQuantities = prodQuantities.reduce((a,b) => a+b);
        
        let addonQuantities = element.products.map(x => x.selected_addons.map(y => y.quantity));
        addonQuantities = [].concat(...addonQuantities);
        addonQuantities = addonQuantities.length > 0 ? addonQuantities.reduce((a,b) => a+b) : 0;
  
        if (aggregatedQuantities.orders[orderId]) {
          aggregatedQuantities.orders[orderId] += prodQuantities + addonQuantities;
        } else {
          aggregatedQuantities.orders[orderId] = prodQuantities + addonQuantities;
        }


        // Calculate Quantity by Addon
        element.products.forEach(productData => {
          productData.selected_addons.forEach(addonObj => {
            if (typeof aggregatedQuantities.addons[addonObj._id] !== 'undefined') {
              aggregatedQuantities.addons[addonObj._id] += addonObj.quantity;
            } else {
              aggregatedQuantities.addons[addonObj._id] = addonObj.quantity;
            }
          })
        });


        // Calculate Quantity by Product
      element.products.forEach(productData => {
        if (typeof aggregatedQuantities.products[productData.productId._id] !== 'undefined') {
          aggregatedQuantities.products[productData.productId._id] += productData.quantity;
        } else {
          aggregatedQuantities.products[productData.productId._id] = productData.quantity;
        }
      });

      });

      let countTotal = Object.keys(aggregatedQuantities.orders).map(x => aggregatedQuantities.orders[x]);
      aggregatedQuantities.total = countTotal.length > 0 ? countTotal.reduce((a,b) => a+b) : 0;
    }

    console.log(aggregatedQuantities);
    setQuantityBunch({...aggregatedQuantities})
  }

  const formatDate = (value) => {
    return moment(value).format('DD-MMM-YYYY');
  }

  const findData = (scope, id, data) => {
    let result = "N/A";
    switch (scope) {
      case 'product_quantity':
        let foundProduct = data.products.find(x => x.productId._id === id);
        result = foundProduct ? foundProduct.quantity : "-" ;
        break;
      case 'addon_quantity':
        let foundAddonQuantity = "-";
        let addonData;
        data.products.find(x => {
          addonData = x.selected_addons.find(y => y._id == id);
          if (addonData) {
            foundAddonQuantity = addonData?.quantity || "-";
            return true;
          }
        });
        console.log(foundAddonQuantity, data.billing_name, addonData)
        result = foundAddonQuantity;
        break;
    }

    return result;
  }

  const getAddonDetail = (id) => {
    return tableData.addons.find(x => x._id == id);
  }

  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'SGD',
  });

  var printAddons = (product) => {
    return (
      product.selected_addons.length == 1 
        ? getAddonDetail(product.selected_addons[0]._id).addon_name 
        : product.selected_addons.map((addon, addonIndex) => (
          `${addonIndex == 0 ? "" : " /" } ${addon.quantity}x ${getAddonDetail(addon._id).addon_name}` 
        ))
    )
  }

  var printAddonsTotal = (product) => {
    if (product.productId.addons.length) {
      return product.productId.addons.map((addon, addonIndex) => {
        return quantityBunch.addons[addon._id] ? `${addonIndex == 0 ? "" : " /" } ${quantityBunch.addons[addon._id]}x ${addon.addon_name}` : false
      })
    }
    return null;
    // return (
    //   product.productId.addons.length > 0 ? product.productId.addons.map((addon, addonIndex) => (
    //     `${addonIndex == 0 ? "" : " /" } ${quantityBunch.addons[_id]}x ${getAddonDetail(addon._id).addon_name}` 
    //   )) : null
    // )
  }


  return (
    <div className={`dgt-mega-panel ${props.isPanelOpen ? 'is--opened' : ''} packaing`}>
        <div className="dgt-panel-header">
          <h2>Host Name: 
            {packageData.data.length && packageData.data[0].host_id.username && (
              <span className="light">{packageData.data.length ? packageData.data[0].host_profile.username || packageData.data[0].host_id.username : "N/A"}</span>
            )}
          </h2>
          <h2>Group Buy Date: 
            <span className="light">
            {packageData.data.length ? formatDate(packageData.data[0].group_buy_date) : "N/A"} 
            {packageData.data.length && packageData.data[0].timeslot ? ` - ${moment(packageData.data[0].timeslot.from, "HH:mm").format('hh:mm A')} - ${moment(packageData.data[0].timeslot.to, "HH:mm").format('hh:mm A')}` : ""}</span>
          </h2>
        </div>
        <div className="dgt-panel-header">
          <h2>Host Phone: 
            <span className="light">{packageData.data.length && packageData.data[0].host_profile.phone_no ? packageData.data[0].host_profile.phone_no : "N/A"}</span>
          </h2>
          <h2>Host Address: 
          <span className="light">{packageData.data.length && packageData.data[0].host_profile.address ? packageData.data[0].host_profile.address : "N/A"}</span>
          </h2>
        </div>
        <span onClick={props.closePanel} className="dgt-toggler hide-on-print"><Close /></span>

        <div style={{textAlign: 'right', marginBottom: 30}} className="table-actions hide-on-print">
          <Button onClick={() => {window.print()}} variant="contained" color="primary" component="label" >Export</Button>
        </div>

        <Grid container spacing="3">
          {tableData.customers.length && tableData.customers.map((order, index) => (
            <Grid item xs={4}>
              <h3>{index+1}) {order.billing_name}</h3>
              {order.categories.length > 0 && order.categories.map(object => (
                <>
                  {object.category && (
                    <p className="category-title">{object.category?.category_name}</p>
                  )}
                  {object.products.length && object.products.map(product => (
                    <p> 
                      - <strong>{product.quantity}</strong> {product.productId.product_name}  &nbsp;
                      {product.selected_addons.length > 0 && (
                        <span className="typo-addons">
                          ( { printAddons(product) } )
                        </span>
                      )}
                    </p>
                  ))}
                </>
              ))}
            </Grid>
          ))}
        </Grid>

        <h2 style={{marginTop: 60}}>Breakdown</h2>
        <Grid container spacing="3">
          {tableData.categories.length && tableData.categories.map((object, index) => (
            <Grid item xs={4}>
              {object.category && (
                <p className="category-title">{object.category?.category_name}</p>
              )}
              {object.products.length && object.products.map(product => (
                <p> 
                  - <strong>{quantityBunch.products[product.productId._id]}</strong> {product.productId.product_name} &nbsp;
                  {product.selected_addons.length > 0 && (
                    <span className="typo-addons">
                      ( {printAddonsTotal(product)} )
                    </span>
                  )}
                </p>
              ))}
            </Grid>
          ))}
        </Grid>

    </div>
  );
}
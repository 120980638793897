import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import { userData } from "../../dummyData";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import WidgetLgMerchant from "../../components/widgetLg/WidgetLgMerchant";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function Home() {
  const [role, setRole] = useState("");
  const history = useHistory();

  const token = localStorage.getItem("token");

  useEffect(async () => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
      await axios.get(`${process.env.React_App_baseURL}/user/account`)
        .then(({ data }) => {
          if (data.status === 200) {
            setRole(data.session.user_id.role);
            console.log(data.session);
            getLatestOrders();
          } else {
            toast.info("Only Merchant & Host are authorized!");
            history.push("/login");
          }
        })
    } else {
      history.push("/login");
    }
  }, [])

  const [latestOrders, setLatestOrders] = useState([]);

  const getLatestOrders = () => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
      axios.get(`${process.env.React_App_baseURL}/latest-orders`)
        .then(({ data }) => {
          if (data.status === 200) {
            console.log(data.orders);
            setLatestOrders(data.orders);
          }
        })
    }
  }


  // const chartData =() => {
  //   // let 
  // }

  return (
    <div>
      <Topbar />
      <div className="container">
        <Sidebar />
        <div className="home">
          {/* <FeaturedInfo /> */}
          {/* <Chart data={latestOrders} title="Order Analytics" grid dataKey="Active Orders" /> */}
          <div className="homeWidgets">
            {/* <WidgetSm /> */}
            {role == 'host' && (
              <WidgetLg latestOrders={latestOrders} />
            )}
            {role == 'merchant' && (
              <WidgetLgMerchant />
            )}

          </div>
        </div>
      </div>
    </div >
  );
}

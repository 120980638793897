import "./newProduct.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Paper, TextField, Button, Typography, Link, Select, InputLabel, MenuItem, FormControl } from '@material-ui/core'
import axios from 'axios';
import { DeleteOutline } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export default function NewProduct() {
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [img_url, setImg_url] = useState("");
  const [filename, setFilename] = useState("Choose Image");
  const [user, setUser] = useState();
  const [addons, setAddons] = useState([]);
  const [productType, setProductType] = useState("single");
  const [productUnits, setProductUnits] = useState(2);
  const [maxAddons, setMaxAddons] = useState(1);
  const [isAddonsMandatory, setIsAddonsMandatory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setSelectedCategory] = useState(null);

  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(async () => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
      const user = await axios.get(`${process.env.React_App_baseURL}/user/account`)
        .then(({ data }) => data.session);
      if (user.user_id.role === "merchant") {
        getAllCategories();
        return;
      } else {
        toast.info("Only Merchant is authorized!");
        history.push("/login");
      }
    }
    else history.push("/login");


    
  }, []);

  const getAllCategories = async () => {
    await axios.get(`${process.env.React_App_baseURL}/categories`)
            .then(({ data }) => {
              console.log(data.categories);
              setCategories(data.categories);
            });
  }


  const fileSelectedHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) setImg_url(reader.result);
    }
    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!productName || !price || !selectedFile) return toast.error("Please fill all fields & product image!");

    const fd = new FormData();
    fd.append('img_url', selectedFile);
    fd.append('description', description);
    fd.append('category', category);
    fd.append('product_name', productName);
    fd.append('price', price);
    fd.append('is_active', isActive);
    fd.append("addons", JSON.stringify(addons));
    fd.append("product_type", productType);
    fd.append("product_units", productUnits);
    fd.append("max_addons", maxAddons);
    fd.append("is_addons_mandatory", true);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = token;

    try {
      await axios
        .post(`${process.env.React_App_baseURL}/product/create`, fd, config)
        .then(({ data }) => {
          if (data.status === 200) {
            // toast.success("Product add successfully!");
            history.push("/products");
          } else {
            toast.error("Product not created!, please try again");
          }
        });

    } catch (error) {
      let message = error.response ? error.response.data.message : "Only image files are allowed!";
      toast.error(message);
    }
  };

  const deleteAddon = (index) => {
    addons.splice(index, 1);
    setAddons([...addons]);
  }

  const addAddon = () => {
    addons.push({ addon_name: "Option Name", price: "00.00" });
    setAddons([...addons]);
  }

  const updateAddon = (key, index, value) => {
    if (addons[index][key] !== value) {
      addons[index][key] = value;
      setAddons([...addons])
    }
  }

  const paperStyle = { padding: 20, width: 400, margin: "" }
  const btnstyle = { margin: '8px 0' }

  let start = 0;
  const handlePrice = (e) => {
    start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    e.target.value = value;
    // setInput(value);
    if (val.length > 0) {
      e.target.value = Number(value).toFixed(2);
      e.target.setSelectionRange(start, start);
      setPrice(Number(value).toFixed(2));
    }
  }

  const hanldeProductUnits = (e) => {
    let result = e.target.value.replace(/\./g, "");
    setProductUnits(result);
  }

  const hanldeMaxAddons = (e) => {
    let result = e.target.value.replace(/\./g, "");
    setMaxAddons(result);
  }


  return (
    <div>
      <Topbar />
      <div className="container">
        <Sidebar />
        <div className="newProduct" style={{ display: 'flex' }}>
          <div >
            <Grid>
              <Paper elevation={0} style={paperStyle}>
                <Grid align='left'>
                  <h2>New Product</h2>
                </Grid>
                <br />
                <form encType='multipart/form-data'>
                  <TextField className="addProductItem" label='Product Name' placeholder='Enter Product Name' fullWidth name="product_name" value={productName} onChange={(e) => setProductName(e.target.value)} />
                  <br />
                  <TextField className="addProductItem" label='Description' placeholder="Description" fullWidth multiline minRows={2} maxRows={5} name="product_name" value={description} onChange={(e) => setDescription(e.target.value)} />
                  <br /><br />

                  <InputLabel id="demo-simple-select-helper-label1">Category</InputLabel>
                  <Select labelId="demo-simple-select-helper-label1" id="demo-simple-select-helper1"
                    value={category} label="Category" onChange={(e) => setSelectedCategory(e.target.value)}
                    fullWidth defaultValue={null}>
                    {categories.length > 0 && categories.map(x => (
                      <MenuItem value={x._id}>{x.category_name}</MenuItem>
                    ))}
                  </Select>
                  <br /><br />
                  <TextField className="addProductItem" type="tel" label='Price' placeholder='0.00' fullWidth name="price" value={price} onChange={handlePrice} />
                  <br /><br />

                  <InputLabel id="demo-simple-select-helper-label">Product Type</InputLabel>
                  <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                    value={productType} label="Product Type" onChange={(e) => setProductType(e.target.value)}
                    fullWidth defaultValue="single">
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="bundle">Bundle</MenuItem>
                  </Select>
                  <br /><br />
                  {productType === "bundle" && (
                    <div>
                      <TextField type="number" label='Product Units'
                        value={productUnits} onChange={hanldeProductUnits}
                        InputProps={{ inputProps: { min: 2 } }} />
                      <TextField type="number" label='Max Options'
                        value={maxAddons} onChange={hanldeMaxAddons}
                        InputProps={{ inputProps: { min: 2 } }} />
                    </div>
                  )}

                  <br />
                  {/* <div className="addProductItem">
                    <label style={{ fontWeight: '500' }}>Is Addons Mandatory ?</label>
                    <label className="switch">
                      <input type="checkbox" defaultValue={false} value={isAddonsMandatory} onChange={() => setIsAddonsMandatory(!isAddonsMandatory)} />
                      <span className="slider round"></span>
                    </label>
                  </div> */}

                  <div className="addProductItem">
                    <label style={{ fontWeight: '500' }}>Active</label>
                    <label className="switch">
                      <input type="checkbox" defaultChecked name="is_active" value={isActive} onChange={() => setIsActive(!isActive)} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="addProductItem">
                    <Button variant="contained" component="label" >Choose Image
                      <input type="file" name="img_url" hidden onChange={fileSelectedHandler} accept="image/*" />
                    </Button>
                    <div><small>Only jpg, png, gif, svg images are allowed with max size of 5 MB</small></div>
                  </div>

                  <Button onClick={handleSubmit} type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Create Product</Button>
                </form>
                <br />
                <Typography >
                  <Link href="/products" >
                    Back to Product List
                  </Link>
                </Typography>
              </Paper>
            </Grid>
            <ToastContainer position="top-right" />
          </div>
          <div className="ProductImage" style={{ width: '300px', margin: "80px 20px", alignContent: 'center', border: '3px dashed grey', display: 'flex', height: '300px', alignItems: 'center' }}>
            {(selectedFile) ? (

              <img src={img_url} style={{ margin: "0 auto", borderRadius: '50px', height: '300px', width: '300px' }}></img>
            ) : ""}
          </div>

          <div className="addon-wrapper">
            <Button variant="contained" component="label" onClick={addAddon} >Add Option</Button>

            <div className="addon-list">
              {addons.map((x, i) => (
                <div className="addon-item">
                  <input className="addon-name" type="text" key={`addonn_${i}`} value={addons[i].addon_name} onChange={(e) => updateAddon('addon_name', i, e.target.value)} />
                  {/* <input className="addon-price" type="number" key={`addonp_${i}`} value={addons[i].price} onChange={(e) => updateAddon('price', i, e.target.value)} /> */}
                  <div className="addon-actions">
                    <DeleteOutline onClick={() => deleteAddon(i)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div >
  );

}

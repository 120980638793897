import "./sidebar.css";
import {
  Home,
  EmojiFoodBeverage,
  AttachMoney,
  BarChart,
  People,
  Event,
  Fastfood,
  ExitToApp,
  SettingsApplications
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


export default function Sidebar() {
  const history = useHistory();

  const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem("profileInfo");
    history.push('/login');
  }


  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <li>
              <NavLink exact activeClassName="active" to='/' className="sidebarListItem link">
                <Home className="sidebarIcon" />
                Home
              </NavLink>
            </li>

            {userData?.role === "merchant" && (
              <>
                <li>
                  <NavLink activeClassName="active" to='/categories' className="sidebarListItem link">
                    <EmojiFoodBeverage className="sidebarIcon" />
                    Category
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to='/products' className="sidebarListItem link">
                    <EmojiFoodBeverage className="sidebarIcon" />
                    Products
                  </NavLink>
                </li>
              </>
            )}

            {userData?.role === "merchant" && (
              <li>
                <NavLink activeClassName="active" to='/calendar' className="sidebarListItem link">
                  <Event className="sidebarIcon" />
                  Calendar
                </NavLink>
              </li>
            )}

            {/* GB Dates */}
            {userData?.role === "merchant" && (
              <li>
                <NavLink activeClassName="active" to='/group-buys' className="sidebarListItem link">
                  <Event className="sidebarIcon" />
                  GB Dates
                </NavLink>
              </li>
            )}

            <li>
              <NavLink activeClassName="active" to='/orders' className="sidebarListItem link">
                <AttachMoney className="sidebarIcon" />
                Orders
              </NavLink>
            </li>


            {userData?.role === "host" && (
              <li>
                <NavLink activeClassName="active" to='/host-calender' className="sidebarListItem link">
                  <Event className="sidebarIcon" />
                  Calendar
                </NavLink>
              </li>
            )}

            {userData?.role === "merchant" && (
              <li>
                <NavLink activeClassName="active" to='/packaging' className="sidebarListItem link">
                  <Fastfood className="sidebarIcon" />
                  Packing
                </NavLink>
              </li>
            )}

            {/* <li>
              <NavLink activeClassName="active" to='/reviews' className="sidebarListItem link">
                <BarChart className="sidebarIcon" />
                Reviews
              </NavLink>
            </li> */}

            <li>
              <NavLink activeClassName="active" to='/profile' className="sidebarListItem link">
                <People className="sidebarIcon" />
                Profile
              </NavLink>
            </li>

            <li>
              <NavLink activeClassName="active" to='/settings' className="sidebarListItem link">
                <SettingsApplications className="sidebarIcon" />
                Settings
              </NavLink>
            </li>

            <li onClick={handleLogout}>
              <NavLink activeClassName="" to='#' className="sidebarListItem link">
                <ExitToApp className="sidebarIcon" />
                Logout
              </NavLink>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
}

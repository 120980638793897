import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ShoppingBasketOutlined } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";
import CheckoutLogic, { dataService } from "./CheckoutLogic";

let cart = { products: [] };

dataService.getData().subscribe((res) => {
  cart = res;
})

const toggleCheckoutPanel = () => {
  const checkoutWrapper = document.querySelector('.checkout-subtotal-wrapper');
  if (checkoutWrapper) {
    window.scrollTo({
      top: checkoutWrapper.offsetTop,
      behavior: 'smooth',
    })
  }
}

const ConsumerHeader = (props) => {


  return (
    <React.Fragment>
      <header className="top-header">
        <span className="app-title">GroupBuy SG</span>

        <ul>
          <li>
            {/* <NavLink to="/checkout"> */}
            <Badge onClick={toggleCheckoutPanel} color="secondary" badgeContent={cart.products.length}>
              <ShoppingBasketOutlined />
            </Badge>
            {/* </NavLink> */}
          </li>
        </ul>
      </header>
    </React.Fragment>
  )
}

export default ConsumerHeader
